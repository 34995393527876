import React from 'react';

import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import BudgetImg from 'assets/images/budget-panel.svg';
import PremiumImg from 'assets/images/premium-panel.svg';
import StyleImg from 'assets/images/style-panel.svg';

import { ratioExploitableAreaForPvVsTotalArea, wpPerSqm } from 'utils/estimationCalculations';

import { SectionTitle, SelectionCard } from './styled';

const SecondStep = ({ formValues, setFormValues, goToNextStep }) => {
  const { t } = useTranslation('translation');

  const onChangePanelType = (type) => {
    setFormValues((values) => ({ ...values, panel_type: type }));
    goToNextStep();
  };

  const getPrice = (option) => {
    let roofArea;

    if (formValues.roof_area) {
      roofArea = formValues.roof_area;
    } else if (formValues.roof_type === 'whole') {
      roofArea = 120;
    } else if (formValues.roof_type === 'half') {
      roofArea = 60;
    } else if (formValues.roof_type === 'less') {
      roofArea = 30;
    }

    const kwp = (ratioExploitableAreaForPvVsTotalArea * roofArea * wpPerSqm) / 1000;
    switch (option) {
      case 'budget':
        return 0;
      case 'premium':
        return 1400 * kwp - 1300 * kwp;
      case 'style':
        return 1500 * kwp - 1300 * kwp;
      default:
        break;
    }
  };

  return (
    <>
      <SectionTitle>{t('landing.estimation_form.choose_panel_type')}</SectionTitle>
      <Row gutter={[10, 10]} style={{ marginBottom: '1rem' }}>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangePanelType('budget')} selected={formValues.panel_type === 'budget'}>
            <img src={BudgetImg} alt={t('landing.estimation_form.budget')} />
            <h6>{t('landing.estimation_form.budget')}</h6>
            <p>{t('landing.estimation_form.glass_foil_panels')}</p>
            <p className="price">{`+ ${getPrice('budget').toFixed(0)} €`}</p>
          </SelectionCard>
        </Col>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangePanelType('premium')} selected={formValues.panel_type === 'premium'}>
            <img src={PremiumImg} alt={t('landing.estimation_form.premium')} />
            <h6>{t('landing.estimation_form.premium')}</h6>
            <p>{t('landing.estimation_form.glass_glass_panels')}</p>
            <p className="price">{`+ ${getPrice('premium').toFixed(0)} €`}</p>
          </SelectionCard>
        </Col>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangePanelType('style')} selected={formValues.panel_type === 'style'}>
            <img src={StyleImg} alt={t('landing.estimation_form.style')} />
            <h6>{t('landing.estimation_form.style')}</h6>
            <p>{t('landing.estimation_form.chinese_glass_panels')}</p>
            <p className="price">{`+ ${getPrice('style').toFixed(0)} €`}</p>
          </SelectionCard>
        </Col>
      </Row>
    </>
  );
};

export default SecondStep;
