import styled from 'styled-components';
import { Col } from 'antd';

import { breakpoints } from 'utils/breakpoints';
import { fontsizes } from 'utils/fontsizes';

export const FlexCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const StyledInfoCard = styled.div`
  max-width: 30rem;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.isImg ? 0 : '2rem')};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isDark ? props.theme.dark_background : props.isImg ? 'transparent' : props.theme.light_background};

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
  }

  & h5 {
    font-size: 2.5rem;
    color: ${(props) => (props.isDark ? props.theme.light_blue : props.theme.dark_blue)};
    margin-bottom: 1rem;
  }

  & p {
    font-size: 1rem;
    color: ${(props) => (props.isDark ? props.theme.white : props.theme.black)};
  }
`;

export const SectionHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderSection = styled.h1`
  margin-bottom: 2rem;
  font-size: ${fontsizes.h1_sm};
  font-weight: 500;

  @media only screen and (min-width: ${breakpoints.sm}px) {
    font-size: ${fontsizes.h1};
  }
`;

export const ButtonsWrapper = styled.div`
  margin-bottom: 2rem;

  & button {
    width: 2.375rem;
  }
  & button:first-child {
    margin-right: 3.875rem;
  }
`;

export const ContentCard = styled.div`
  border-radius: 0.5rem;
  background-color: ${(p) => p.theme.white};
  padding: 1.5rem !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const CarouselSlideWrapper = styled.div`
  position: relative;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  & img {
    margin-right: 0.625rem;
  }
  & .name {
    font-size: 1.5rem;
  }
  & .city {
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const FormSectionBackground = styled.div`
  background: ${(props) =>
    `linear-gradient(39.81deg, rgba(0, 18, 63, 0) -38.42%, rgba(0, 18, 63, 0.85) 78.08%), url(${props.backgroundImg}) center center no-repeat`};
  display: flex;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  margin: 1rem 0 2.5rem;
  width: 100%;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.white};

  @media only screen and (min-width: ${breakpoints.sm}px) {
    width: 70%;
  }

  @media only screen and (min-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    width: 50%;
  }
`;

export const FormTitle = styled.h5`
  margin: 2.5rem 0 1rem;
  font-size: 2.625rem;
  font-weight: 600;
  color: ${(props) => props.theme.light_blue};
  text-align: center;
`;

export const FormSubtitle = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.white};
  text-align: center;
`;

export const SavingBannerWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.light_blue};

  & div {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  & img {
    object-fit: cover;
    width: 50%;
    max-width: 25rem;
  }

  & .panel-img {
    display: none;
  }

  & h5 {
    text-align: center;
    font-size: 2.625rem;
    margin: 2rem 0 1rem;
  }

  & span {
    color: ${(props) => props.theme.white};
  }

  & p {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    flex-direction: row;
    align-items: inherit;

    & p {
      margin-bottom: 0;
    }
    & .panel-img {
      display: block;
    }
    & h5 {
      margin: 0 0 1rem;
    }
    & div {
      padding: 2rem;
      width: 50%;
    }
    & img {
      width: 25%;
    }
  }
`;

export const ChooseSolarbaerTitle = styled.h5`
  font-size: 2.625rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const StyledGoogleImg = styled.img`
  width: 100%;
  max-width: 30rem;
`;

export const StyledExplanationCol = styled(Col)`
  & h6 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
`;
