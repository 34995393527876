import React, { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext();

export const useScrollRef = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const scrollRef = useRef(null);

  return <ScrollContext.Provider value={scrollRef}>{children}</ScrollContext.Provider>;
};
