import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import WomanImg from 'assets/images/landing-saving-woman.png';
import PanelImg from 'assets/images/landing-saving-panel.png';
import { SavingBannerWrapper } from './styled';

const SavingBanner = () => {
  const { t } = useTranslation('translation');

  return (
    <SavingBannerWrapper>
      <img src={WomanImg} alt="Woman" />
      <div>
        <h5>
          <Trans i18nKey="landing.saving_banner_title" components={[<span />]} />
        </h5>
        <p>{t('landing.saving_banner_subtitle')}</p>
      </div>
      <img src={PanelImg} alt="Phovoltaik" className="panel-img" />
    </SavingBannerWrapper>
  );
};

export default SavingBanner;
