import React, { forwardRef } from 'react';

import { useTranslation } from 'react-i18next';
import FormBackgroundImg from 'assets/images/landing-form-background.jpeg';
import EstimationForm from 'components/EstimationForm/EstimationForm';
import { ContentContainer } from 'components/Common/Common';

import { FormSectionBackground, FormSubtitle, FormTitle, FormWrapper } from './styled';

const EstimationFormSection = forwardRef((props, ref) => {
  const { t } = useTranslation('translation');

  return (
    <div ref={ref}>
      <FormSectionBackground backgroundImg={FormBackgroundImg}>
        <ContentContainer
          additionalStyles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <FormTitle>{t('landing.estimation_form_title')}</FormTitle>
          <FormSubtitle>{t('landing.estimation_form_subtitle')}</FormSubtitle>
          <FormWrapper>
            <EstimationForm />
          </FormWrapper>
        </ContentContainer>
      </FormSectionBackground>
    </div>
  );
});

export default EstimationFormSection;
