import React, { useState } from 'react';

import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { InputWrapper, SectionTitle, StyledInput, StyledCheckbox } from './styled';

const FifthStep = ({ formValues, setFormValues }) => {
  const { t } = useTranslation('translation');

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  return (
    <>
      <SectionTitle>{t('landing.estimation_form.step_away')}</SectionTitle>
      <p>{t('landing.estimation_form.enter_details')}</p>
      <Row gutter={16} style={{ margin: '1rem 0' }}>
        <Col span={12} className="gutter-row">
          <InputWrapper>
            <label htmlFor="first_name">{t('landing.estimation_form.first_name')}</label>
            <StyledInput
              size="large"
              name="first_name"
              placeholder={t('landing.estimation_form.first_name')}
              value={formValues.first_name}
              onChange={(e) => setFormValues((values) => ({ ...values, first_name: e.target.value }))}
            />
          </InputWrapper>
        </Col>
        <Col span={12} className="gutter-row">
          <InputWrapper>
            <label htmlFor="last_name">{t('landing.estimation_form.last_name')}</label>
            <StyledInput
              size="large"
              name="last_name"
              placeholder={t('landing.estimation_form.last_name')}
              value={formValues.last_name}
              onChange={(e) => setFormValues((values) => ({ ...values, last_name: e.target.value }))}
            />
          </InputWrapper>
        </Col>
      </Row>
      <Row gutter={16} style={{ margin: '0 0 1rem' }}>
        <Col span={12} className="gutter-row">
          <InputWrapper>
            <label htmlFor="email">{t('landing.estimation_form.email')}</label>
            <StyledInput
              size="large"
              name="email"
              placeholder={t('landing.estimation_form.email')}
              addonBefore={<MailOutlined />}
              value={formValues.email}
              onChange={(e) => {
                setFormValues((values) => ({ ...values, email: e.target.value }));
                /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value) ? setEmailError(false) : setEmailError(true);
              }}
              status={emailError && 'error'}
            />
          </InputWrapper>
        </Col>
        <Col span={12} className="gutter-row">
          <InputWrapper>
            <label htmlFor="telephone">{t('landing.estimation_form.telephone')}</label>
            <StyledInput
              size="large"
              name="telephone"
              placeholder={t('landing.estimation_form.telephone')}
              addonBefore={<PhoneOutlined />}
              value={formValues.phone}
              onChange={(e) => {
                setFormValues((values) => ({ ...values, phone: e.target.value }));
                /^[\d()+\-,\s]+$/.test(e.target.value) ? setPhoneError(false) : setPhoneError(true);
              }}
              status={phoneError && 'error'}
            />
          </InputWrapper>
        </Col>
      </Row>
      <StyledCheckbox
        checked={formValues.toc_accepted}
        onChange={(e) => setFormValues((values) => ({ ...values, toc_accepted: e.target.checked }))}>
        {t('landing.estimation_form.read_toc')}
      </StyledCheckbox>
    </>
  );
};

export default FifthStep;
