import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { Background, ContentContainer } from 'components/Common/Common';
import GoogleReviewImg from 'assets/images/landing-google-review.png';
import DynamicTeamImg from 'assets/images/landing-dynamic-team.svg';
import UnmatchedQualityImg from 'assets/images/landing-unmatched-quality.svg';
import PremiumPanelsImg from 'assets/images/landing-premium-panels.svg';
import EfficientProcessImg from 'assets/images/landing-efficient-process.svg';

import { ChooseSolarbaerTitle, StyledGoogleImg, FlexCol, StyledExplanationCol } from './styled';

const ChooseSolarBear = () => {
  const { t } = useTranslation('translation');

  return (
    <Background>
      <ContentContainer>
        <Row gutter={[32, 32]}>
          <FlexCol xs={{ span: 24 }} lg={{ span: 12 }} className="gutter-row">
            <StyledGoogleImg src={GoogleReviewImg} alt="Google rating" style={{ width: '100%' }} />
          </FlexCol>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="gutter-row">
            <ChooseSolarbaerTitle>{t('landing.why_choose_solarbaer')}</ChooseSolarbaerTitle>
            <Row gutter={[32, 32]} style={{ marginBottom: '2rem' }}>
              <StyledExplanationCol span={12} className="gutter-row">
                <img src={DynamicTeamImg} alt="Dynamic team" />
                <h6>{t('landing.dynamic_team_title')}</h6>
                <p>{t('landing.dynamic_team_text')}</p>
              </StyledExplanationCol>
              <StyledExplanationCol span={12} className="gutter-row">
                <img src={UnmatchedQualityImg} alt="Unmatched Quality" />
                <h6>{t('landing.unmatched_quality_title')}</h6>
                <p>{t('landing.unmatched_quality_text')}</p>
              </StyledExplanationCol>
            </Row>
            <Row gutter={[32, 32]}>
              <StyledExplanationCol span={12} className="gutter-row">
                <img src={PremiumPanelsImg} alt="Premium panels" />
                <h6>{t('landing.premium_panels_title')}</h6>
                <p>{t('landing.premium_panels_text')}</p>
              </StyledExplanationCol>
              <StyledExplanationCol span={12} className="gutter-row">
                <img src={EfficientProcessImg} alt="Efficient process" />
                <h6>{t('landing.efficient_process_title')}</h6>
                <p>{t('landing.efficient_process_text')}</p>
              </StyledExplanationCol>
            </Row>
          </Col>
        </Row>
      </ContentContainer>
    </Background>
  );
};

export default ChooseSolarBear;
