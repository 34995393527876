const panelOptions = ['budget', 'premium', 'style'];
const inverterOptions = ['european', 'asian'];

export const wpPerSqm = 230;
export const ratioExploitableAreaForPvVsTotalArea = 0.8;

export const calculatePrice = (areaOption, panelType, inverterType, storage) => {
  let price = 0;
  let roofArea;

  if (areaOption === 'whole') {
    roofArea = 120;
  } else if (areaOption === 'half') {
    roofArea = 60;
  } else if (areaOption === 'less') {
    roofArea = 30;
  } else {
    roofArea = areaOption;
  }

  const kwp = (ratioExploitableAreaForPvVsTotalArea * roofArea * wpPerSqm) / 1000;

  if (kwp < 3) {
    price += 1500;
  } else if (kwp < 5) {
    price += 1000;
  } else if (kwp < 7) {
    price += 500;
  }

  switch (panelType) {
    case 'budget':
      price += 1300 * kwp;
      break;
    case 'premium':
      price += 1400 * kwp;
      break;
    case 'style':
      price += 1500 * kwp;
      break;
    default:
      throw new Error(`${panelType} not in ${panelOptions}`);
  }

  switch (inverterType) {
    case 'european':
      price += 0;
      break;
    case 'asian':
      price += 1000;
      break;
    default:
      throw new Error(`${inverterType} not in ${inverterOptions}`);
  }

  if (storage) {
    // for 5.1 kWh
    price += 4000;
  }

  return [price.toFixed(0).toLocaleString('en-US', { useGrouping: true }), kwp];
};
