import React from 'react';

import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import YesBatteryIcon from 'assets/images/yes-battery-icon.svg';
import NotSureBatteryIcon from 'assets/images/not-sure-battery-icon.svg';
import NoBatteryIcon from 'assets/images/no-battery-icon.svg';
import StarIcon from 'assets/images/star-icon.svg';

import { SectionTitle, SelectionCard, DisclaimerWrapper } from './styled';

const FourthStep = ({ formValues, setFormValues, goToNextStep }) => {
  const { t } = useTranslation('translation');

  const onChangeBatteryMode = (mode) => {
    setFormValues((values) => ({ ...values, battery: mode }));
    goToNextStep();
  };

  return (
    <>
      <SectionTitle>{t('landing.estimation_form.get_battery')}</SectionTitle>
      <Row gutter={[10, 10]} style={{ marginBottom: '1rem' }}>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangeBatteryMode('yes')} selected={formValues.battery === 'yes'}>
            <img src={YesBatteryIcon} alt={t('landing.estimation_form.yes_battery')} />
            <h6>{t('landing.estimation_form.yes_battery')}</h6>
            <p className="price">+ 4 000 €</p>
          </SelectionCard>
        </Col>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangeBatteryMode('not_sure')} selected={formValues.battery === 'not_sure'}>
            <img src={NotSureBatteryIcon} alt={t('landing.estimation_form.not_sure_battery')} />
            <h6>{t('landing.estimation_form.not_sure_battery')}</h6>
          </SelectionCard>
        </Col>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangeBatteryMode('no')} selected={formValues.battery === 'no'}>
            <img src={NoBatteryIcon} alt={t('landing.estimation_form.no_battery')} />
            <h6>{t('landing.estimation_form.no_battery')}</h6>
          </SelectionCard>
        </Col>
      </Row>
      <DisclaimerWrapper>
        <img src={StarIcon} alt="Star" />
        <p>{t('landing.estimation_form.battery_disclaimer')}</p>
      </DisclaimerWrapper>
    </>
  );
};

export default FourthStep;
