const defaultTheme = {
  black: '#000000',
  white: '#ffffff',
  gray: '#DADADA',
  light_gray: '#ededed',
  light_gray_opacity: '#EDEDED33',
  dirty_white: '#fafafa',
  white_blue: '#f9fbfd',
  pale_blue: '#a2d1f4',
  light_blue: '#70BFF9',
  dark_blue: '#00123F',
  bright_blue: '#4fc3ff',
  dark_green: '#10434a',
  bright_green: '#6adeb5',
  dark_background: '#06003F',
  light_background: '#EAF0F4',
};

export default defaultTheme;
