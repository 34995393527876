import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button/Button';
import LeadService from 'services/LeadService';
import { eventTrack } from 'utils/googleAnalytics';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import FifthStep from './FifthStep';
import { StepProgressWrapper, FormWrapper, StepProgressLine, StepProgressCircle, ButtonsWrapper } from './styled';
import SixthStep from './SixthStep';

const EstimationForm = () => {
  const { t } = useTranslation('translation');

  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    roof_area: 0,
    roof_type: '',
    panel_type: '',
    inverter: '',
    battery: '',
    phone: '',
    email: '',
    first_name: '',
    last_name: '',
    toc_accepted: false,
  });

  const nextClickHandler = async () => {
    eventTrack('Landing page', 'Estimation form next button click', 'Estimation form');
    setStep(step + 1);
    if (step === 5) {
      await LeadService.createLead({
        phone: formValues.phone,
        email: formValues.email,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        description: JSON.stringify({
          roof_area: formValues.roof_area,
          roof_type: formValues.roof_type,
          panel_type: formValues.panel_type,
          inverter: formValues.inverter,
          battery: formValues.battery,
        }),
        campaign: localStorage.getItem('utmCampaign') || null,
        source: localStorage.getItem('utmSource') || null,
        medium: localStorage.getItem('utmMedium') || null,
      });
    }
  };

  const nextDisableCheck = () => {
    switch (step) {
      case 1:
        return !formValues.roof_area && !formValues.roof_type;
      case 2:
        return !formValues.panel_type;
      case 3:
        return !formValues.inverter;
      case 4:
        return !formValues.battery;
      case 5:
        return (
          !formValues.first_name ||
          !formValues.last_name ||
          (!formValues.phone &&
            !/^[\d()+\-,\s]+$/.test(formValues.phone) &&
            !formValues.email &&
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formValues.email)) ||
          !formValues.toc_accepted
        );
      default:
        break;
    }
  };

  return (
    <FormWrapper>
      <StepProgressWrapper>
        {(() => {
          const arr = [];
          for (let i = 1; i < 6; i++) {
            arr.push(
              <>
                <StepProgressCircle isCompleted={i <= step}>{i}</StepProgressCircle>
                {i !== 5 && <StepProgressLine isCompleted={i < step} isInProgress={i === step} />}
              </>
            );
          }
          return arr;
        })()}
      </StepProgressWrapper>
      {(() => {
        switch (step) {
          case 1:
            return <FirstStep formValues={formValues} setFormValues={setFormValues} goToNextStep={() => setStep(2)} />;
          case 2:
            return <SecondStep formValues={formValues} setFormValues={setFormValues} goToNextStep={() => setStep(3)} />;
          case 3:
            return <ThirdStep formValues={formValues} setFormValues={setFormValues} goToNextStep={() => setStep(4)} />;
          case 4:
            return <FourthStep formValues={formValues} setFormValues={setFormValues} goToNextStep={() => setStep(5)} />;
          case 5:
            return <FifthStep formValues={formValues} setFormValues={setFormValues} />;
          case 6:
            return <SixthStep formValues={formValues} />;
          default:
            break;
        }
      })()}
      {step !== 6 && (
        <ButtonsWrapper>
          {step !== 1 && (
            <Button
              buttonStyle="primary"
              buttonSize="medium"
              onClick={() => {
                eventTrack('Landing page', 'Estimation form back button click', 'Estimation form');
                setStep(step - 1);
              }}
              additionalStyles={{
                backgroundColor: '#C6E7FF',
                borderColor: '#C6E7FF',
              }}>
              <p>{t('landing.estimation_form.back_btn')}</p>
            </Button>
          )}
          <Button
            buttonStyle="dark"
            buttonSize="medium"
            disabled={nextDisableCheck()}
            onClick={nextClickHandler}
            additionalStyles={{
              marginLeft: step === 1 ? 'auto' : 0,
            }}>
            <p>{t(`landing.estimation_form.${step === 5 ? 'submit_btn' : 'next_btn'}`)}</p>
          </Button>
        </ButtonsWrapper>
      )}
    </FormWrapper>
  );
};

export default EstimationForm;
