import React from 'react';

import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import ChineseInverterImg from 'assets/images/chinese-inverter.svg';
import EuropeanInverterImg from 'assets/images/european-inverter.svg';
import StarIcon from 'assets/images/star-icon.svg';

import { DisclaimerWrapper, SectionTitle, SelectionCard } from './styled';

const ThirdStep = ({ formValues, setFormValues, goToNextStep }) => {
  const { t } = useTranslation('translation');

  const onChangeInverterType = (type) => {
    setFormValues((values) => ({ ...values, inverter: type }));
    goToNextStep();
  };

  return (
    <>
      <SectionTitle>{t('landing.estimation_form.choose_inverter')}</SectionTitle>
      <Row gutter={[10, 10]} style={{ marginBottom: '1rem' }}>
        <Col span={12} className="gutter-row">
          <SelectionCard onClick={() => onChangeInverterType('asian')} selected={formValues.inverter === 'asian'}>
            <img src={ChineseInverterImg} alt={t('landing.estimation_form.chinese_inverter')} />
            <h6>{t('landing.estimation_form.chinese_inverter')}</h6>
            <p>{t('landing.estimation_form.price_performance_winner')}</p>
            <p className="price">+ 0 €</p>
          </SelectionCard>
        </Col>
        <Col span={12} className="gutter-row">
          <SelectionCard onClick={() => onChangeInverterType('european')} selected={formValues.inverter === 'european'}>
            <img src={EuropeanInverterImg} alt={t('landing.estimation_form.european_inverter')} />
            <h6>{t('landing.estimation_form.european_inverter')}</h6>
            <p>{t('landing.estimation_form.hightech_design')}</p>
            <p className="price">+ 1 000 €</p>
          </SelectionCard>
        </Col>
      </Row>
      <DisclaimerWrapper>
        <img src={StarIcon} alt="Star" />
        <p>{t('landing.estimation_form.customer_choice_disclaimer')}</p>
      </DisclaimerWrapper>
    </>
  );
};

export default ThirdStep;
