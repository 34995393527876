import { Checkbox, Input, InputNumber } from 'antd';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  min-height: 29rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const StepProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const StepProgressCircle = styled.div`
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background-color: ${(props) => (props.isCompleted ? props.theme.light_blue : '#EFF0F6')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${(props) => (props.isCompleted ? props.theme.white : props.theme.dark_blue)};
`;

export const StepProgressLine = styled.div`
  width: 13%;
  height: 0.375rem;
  border-radius: 2.5rem;
  background-color: ${(props) => (props.isCompleted ? props.theme.light_blue : '#EFF0F6')};
  background: ${(props) => props.isInProgress && 'linear-gradient(90deg, #70BFF9 50%, #EFF0F6 50%)'};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`;

export const SectionTitle = styled.h5`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.dark_blue};
  margin-bottom: 1rem;

  & span {
    color: ${(props) => props.theme.light_blue};
  }
`;

export const StyledInput = styled(Input)`
  border: none;
  background-color: #f8f8f8;
`;

export const StyledInputNumber = styled(InputNumber)`
  border: none;
  background-color: #f8f8f8;
  margin-right: 0.5rem;
`;

export const RoofInputWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;

  & input {
    max-width: 6rem;
    margin-right: 1rem;
  }
`;

export const HelperText = styled.p`
  margin: 1rem 0 0.5rem;
  font-weight: 600;
`;

export const SelectionCard = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.625rem;
  border-style: solid;
  border-width: ${(props) => (props.selected ? '2px' : '1px')};
  border-radius: 1rem;
  border-color: ${(props) => (props.selected ? props.theme.dark_blue : '#F1F1F1')};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  & img {
    width: 3.5rem;
    height: 3.5rem;
  }

  & h6 {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 700;
  }

  & p {
    font-size: 0.75rem;
    align-self: flex-start;
    margin-top: 0.5rem;
    color: #4f4f4f;
  }

  & .price {
    color: #6a8695;
    font-weight: 600;
  }
`;

export const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & p {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & label {
    color: ${(props) => props.theme.dark_blue};
    margin-bottom: 0.5rem;
  }
  & .ant-input-group-addon {
    border: none;
    background-color: ${(props) => props.theme.light_gray};
  }
  & input {
    border: none;
    background-color: #f8f8f8;
  }
  & .ant-input-group-wrapper-status-error {
    border: 1px solid red;
    border-radius: 0.5rem;
  }
`;

export const CalculationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p {
    margin-bottom: 1rem;
  }

  & div {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  & .divider {
    width: 90%;
    height: 1px;
    background-color: ${(props) => props.theme.dark_blue};
  }

  & .price {
    font-weight: 600;
  }

  & .disclaimer {
    margin-top: 2rem;
    font-size: 0.875rem;
    text-align: center;
    & span {
      font-weight: 600;
      display: block;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  padding-left: 0.5rem;
  & .ant-checkbox-inner {
    width: 1.2rem;
    height: 1.2rem;
  }

  & .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${(props) => props.theme.dark_blue};
    border-color: ${(props) => props.theme.dark_blue};
  }
  & .ant-checkbox-checked > .ant-checkbox-inner:hover {
    background-color: ${(props) => props.theme.dark_blue};
  }
`;
