import styled from 'styled-components';
import { Col } from 'antd';

import { breakpoints } from 'utils/breakpoints';

export const FlexCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FirstSectionWrapper = styled.div`
  background: linear-gradient(
    90deg,
    rgba(206, 231, 245, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(199, 217, 226, 1) 100%
  );
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const LandingTitlesWrapper = styled.div`
  margin-bottom: 2rem;

  & h1 {
    font-size: 2.5rem;
    color: ${(props) => props.theme.dark_blue};
  }
  & h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1rem 0;
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    text-align: left;
    padding-left: 2rem;
    margin-bottom: 0;
    & h1 {
      font-size: 3.5rem;
    }
    & h3 {
      font-size: 2rem;
    }
  }
`;

export const LandingMainImage = styled.img`
  height: 100%;
  max-width: 100%;
`;

export const PositionLabel = styled.div`
  background-color: #ffe55b;
  display: inline-flex;
  padding: 0.3rem 0.5rem;
  margin-bottom: 1rem;
  text-align: left;

  & svg {
    margin-right: 0.3rem;
  }
`;
