import React from 'react';

import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import WholeRoofImg from 'assets/images/whole-roof.svg';
import HalfRoofImg from 'assets/images/half-roof.svg';
import LessRoofImg from 'assets/images/less-roof.svg';

import { SectionTitle, RoofInputWrapper, HelperText, SelectionCard, StyledInputNumber } from './styled';

const FirstStep = ({ formValues, setFormValues, goToNextStep }) => {
  const { t } = useTranslation('translation');

  const onChangeRoofType = (type) => {
    setFormValues((values) => ({ ...values, roof_type: type }));
    goToNextStep();
  };

  const handleChangeArea = (value) => {
    setFormValues((values) => ({ ...values, roof_area: value }));
  };

  return (
    <>
      <SectionTitle>{t('landing.estimation_form.choose_roof_area')}</SectionTitle>
      <p>{t('landing.estimation_form.type_roof_area')}</p>
      <RoofInputWrapper>
        <StyledInputNumber
          size="large"
          placeholder={t('landing.estimation_form.area')}
          value={formValues.roof_area}
          onChange={handleChangeArea}
        />
        <p>m2</p>
      </RoofInputWrapper>
      <HelperText>{t('landing.estimation_form.choose_other_option')}</HelperText>
      <Row gutter={[10, 10]} style={{ marginBottom: '1rem' }}>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangeRoofType('whole')} selected={formValues.roof_type === 'whole'}>
            <img src={WholeRoofImg} alt={t('landing.estimation_form.whole_roof')} />
            <h6>{t('landing.estimation_form.whole_roof')}</h6>
          </SelectionCard>
        </Col>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangeRoofType('half')} selected={formValues.roof_type === 'half'}>
            <img src={HalfRoofImg} alt={t('landing.estimation_form.half_roof')} />
            <h6>{t('landing.estimation_form.half_roof')}</h6>
          </SelectionCard>
        </Col>
        <Col span={8} className="gutter-row">
          <SelectionCard onClick={() => onChangeRoofType('less')} selected={formValues.roof_type === 'less'}>
            <img src={LessRoofImg} alt={t('landing.estimation_form.less')} />
            <h6>{t('landing.estimation_form.less')}</h6>
          </SelectionCard>
        </Col>
      </Row>
    </>
  );
};

export default FirstStep;
