import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from 'App';
import Home from 'pages/Home/Home';
import Calculator from 'pages/Calculator/Calculator';
import Privacy from 'pages/Privacy/Privacy';
import Impressum from 'pages/Impressum/Impressum';
import Contact from 'pages/Contact/Contact';
import EnergieManagementPost from 'pages/EnergieManagementPost/EnergieManagementPost';
import AllRoutes from './AllRoutes';
import LandingPage from './LandingPage/LandingPage';

const Routing = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<App />}>
        <Route path={AllRoutes.Landing} exact element={<LandingPage />} />
        <Route path={AllRoutes.Home} exact element={<Home />} />
        <Route path={AllRoutes.Calculator} element={<Calculator />} />
        <Route path={AllRoutes.Contact} element={<Contact />} />
        <Route path={AllRoutes.Privacy} element={<Privacy />} />
        <Route path={AllRoutes.Impressum} element={<Impressum />} />
        <Route path={AllRoutes.EnergieManagementPost} element={<EnergieManagementPost />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Routing;
