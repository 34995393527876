export default {
  Landing: '/',
  Home: '/home',
  Calculator: '/calculator',
  Contact: '/contact',
  Privacy: '/privacy',
  Impressum: '/impressum',
  Career: '/career',
  EnergieManagementPost: '/energiemanagement/:id/:title',
};
