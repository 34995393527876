import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { Background, ContentContainer } from 'components/Common/Common';
import LandingInvestBenefit from 'assets/images/landing-invest-benefit.png';
import LandingLocalProvider from 'assets/images/landing-local-provider.png';
import LandingTransferSurplus from 'assets/images/landing-transfer-surplus.png';
import LandingPricePerformance from 'assets/images/landing-price-performance.png';
import { StyledInfoCard, FlexCol } from './styled';

const InfoCards = () => {
  const { t } = useTranslation('translation');

  const cardsContent = [
    {
      title: t('landing.invest_once_title'),
      text: t('landing.invest_once_text'),
      img: LandingInvestBenefit,
    },
    {
      title: t('landing.earn_from_installation_title'),
      text: t('landing.earn_from_installation_text'),
      img: LandingTransferSurplus,
    },
    {
      title: t('landing.local_provider_installation_title'),
      text: t('landing.local_provider_installation_text'),
      img: LandingLocalProvider,
    },
    {
      title: t('landing.price_performance_ratio_title'),
      text: t('landing.price_performance_ratio_text'),
      img: LandingPricePerformance,
    },
  ];

  return (
    <Background>
      <ContentContainer additionalStyles={{ maxWidth: '100%' }}>
        {cardsContent.map((card, i) => (
          <Row key={i} gutter={[32, 32]} style={{ marginBottom: '2rem' }}>
            <FlexCol xs={{ span: 24 }} lg={{ order: (i + 1) % 2 === 0 ? 2 : 1, span: 12 }} className="gutter-row">
              <StyledInfoCard isDark={i % 2 === 0}>
                <h5>{card.title}</h5>
                <p>{card.text}</p>
              </StyledInfoCard>
            </FlexCol>
            <FlexCol xs={{ span: 24 }} lg={{ order: (i + 1) % 2 === 0 ? 1 : 2, span: 12 }} className="gutter-row">
              <StyledInfoCard isImg>
                <img src={card.img} alt={card.title} />
              </StyledInfoCard>
            </FlexCol>
          </Row>
        ))}
      </ContentContainer>
    </Background>
  );
};

export default InfoCards;
