import React, { useRef } from 'react';
import { Rate } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { eventTrack } from 'utils/googleAnalytics';
import useWindowDimensions from 'utils/useWindowDimensions';
import { ContentContainer, Background } from 'components/Common/Common';
import { Button } from 'components/Button/Button';
import {
  SectionHeadingWrapper,
  HeaderSection,
  ButtonsWrapper,
  ContentCard,
  CarouselSlideWrapper,
  UserInfoWrapper,
} from './styled';

const Testimonials = () => {
  const { t } = useTranslation('translation');

  const { size, breakpoints } = useWindowDimensions();
  const swiperRef = useRef();

  const mobileNavButtons = (
    <>
      <Button
        buttonStyle="primary"
        buttonSize="full"
        additionalStyles={{
          backgroundColor: '#70BFF9',
          borderColor: '#70BFF9',
          padding: '0.5rem',
          width: '2.375rem',
          top: '40%',
          left: -20,
          position: 'absolute',
        }}
        onClick={() => {
          swiperRef.current?.slidePrev();
          eventTrack('Testimonials', 'Testimonials carousel move', 'Carousel');
        }}>
        <FontAwesomeIcon icon={faArrowLeft} color="#ffffff" fontSize={20} />
      </Button>
      <Button
        buttonStyle="primary"
        buttonSize="full"
        additionalStyles={{
          backgroundColor: '#70BFF9',
          borderColor: '#70BFF9',
          padding: '0.5rem',
          width: '2.375rem',
          top: '40%',
          right: -20,
          position: 'absolute',
        }}
        onClick={() => {
          swiperRef.current?.slideNext();
          eventTrack('Testimonials', 'Testimonials carousel move', 'Carousel');
        }}>
        <FontAwesomeIcon icon={faArrowRight} color="#ffffff" fontSize={20} />
      </Button>
    </>
  );

  const testimonials = [
    {
      id: 1,
      rating: 5,
      text: 'Anfang März 2023 fand das erste Gespräch mit dem jungen Unternehmen durch Herrn Meister der SolarBär GmbH aus Rheinfelden statt. Die super Beratung, das Angebot hat uns gleich, trotz mehreren anderen Angeboten, gleich angesprochen und überzeugt. Nach einer Terminlichen Absprache wurde am 05.09. mit der Montage begonnen und ab dem 08.09.2023 mit der SONNE in die Batterie produziert. Aufgrund des frühen Anmeldens beim Netzbetreibers, wurde auch sogleich der neue Zähler am 18.09.2023 montiert und mit dem Einspeisen begonnen, also TOP. Seit dem 08.09. kann ich am Handy jederzeit die Erzeugung, den Batteriestand, die Einspeisung oder den Verbrauch vom Netz, meinen momentanen Verbrauch vom Haus und den Verbrauch meiner Wärmepumpe verfolgen. Ich bin sehr zufrieden auch mit der Arbeit, der jungen, sehr freundlichen und lustigen Mitarbeiter von Solarbär, die absolut wissen, was sie machen. Ich würde diese Firma wieder nehmen und kann sie nur empfehlen. Auf dem Dach hatte ich zuvor schon 4 Solarthermie Platten und eine kleine Fotovoltaikanlage mit 5 Zellen. Jetzt wurden auf dem Carportdach 10 und auf dem Hausdach 21 Glas/Glaszellen montiert. Vielen Dank !',
      user: 'Peter H.',
      city: '--',
    },
    {
      id: 2,
      rating: 4,
      text: 'Saubere Arbeit und sehr freundliche Mitarbeiter. Wir hatten Im April/Mai ein erstes Gespräch mit Herrn Meister, wo er sehr ausführlich die Anlage beschrieben hat und die Vor- und Nachteile verschiedener Konfigurationen erklärt hat. Leider gab es bis Ende November/Dezember ein paar Lieferengpässe und Ausfälle. Als die Monteure dann aber kamen, waren sie sehr professionell, sauber und haben klar Kommuniziert. Besonders schön fand ich, dass sie viele Vorschläge für die Verlegung der Kabel lieferten, und so ist die Anlage jetzt fast unsichtbar für uns. Richtig schön! Material und Durchführung ohne Mängel (soweit ich das als Laie beurteilen kann). Einziges Manko waren dann die Recht kurzfristigen Termine für die Endmontage und Abnahme.',
      user: 'Tobias K.',
      city: '--',
    },
    {
      id: 3,
      rating: 5,
      text: 'Wir haben uns in 2022 entschlossen eine Photovoltaik Anlage zu installieren. Wir haben mehrere Unternehmen angefragt und haben uns für die Firma Solarbär entschieden, folgend finden Sie unsere Bewertung dieses Unternehmens: Erstberatung und Vorstellung: Die Beratung war fachlich kompetent, man wurde durch die Thematik mit einer schönen Präsentation geführt und Sie nahmen sich viele Zeit, um uns die Technik näher zu bringen und unsere Fragen zu beantworten. Angebot und Lieferzeit: Das Angebot haben wir sehr schnell, nach ca. einer Woche erhalten, dies ist aktuell nicht üblich und wir waren sehr happy darüber. Es war übersichtlich und auf unser Wünsche angepasst, Fragen dazu wurden ebenfalls schnell beantwortet. Lieferzeit, Montage und Mitarbeiter: Die Lieferzeit war gut, ca. 4 Monate und es gab keine längeren Verzögerungen. Die Installation und Montage der Anlage war manchmal etwas wild, jedoch arbeiteten Sie äußerst präzise und sauber. Das Team von Solarbär war sehr freundlich und hilfsbereit. Kurzes Fazit: Kompetente Beratung, schnelles Angebot, präzise Montage und super Mitarbeiter Wir sind mit unserer Wahl sehr zufrieden. Vielen Dank nochamls.',
      user: 'Andreas K.',
      city: '--',
    },
  ];

  return (
    <Background bgColor="#EAF0F4">
      <ContentContainer additionalStyles={{ width: '100%' }}>
        <SectionHeadingWrapper>
          <HeaderSection>{t('home.testimonials')}</HeaderSection>
          {(size === 'lg' || size === 'xl' || size === 'xxl') && (
            <ButtonsWrapper>
              <Button
                buttonStyle="primary"
                buttonSize="full"
                additionalStyles={{
                  backgroundColor: '#a2d1f4',
                  borderColor: '#a2d1f4',
                  padding: '0.5rem',
                }}
                onClick={() => {
                  swiperRef.current?.slidePrev();
                  eventTrack('Testimonials', 'Testimonials carousel move', 'Carousel');
                }}>
                <FontAwesomeIcon icon={faArrowLeft} color="#ffffff" fontSize={20} />
              </Button>
              <Button
                buttonStyle="primary"
                buttonSize="full"
                additionalStyles={{
                  backgroundColor: '#a2d1f4',
                  borderColor: '#a2d1f4',
                  padding: '0.5rem',
                }}
                onClick={() => {
                  swiperRef.current?.slideNext();
                  eventTrack('Testimonials', 'Testimonials carousel move', 'Carousel');
                }}>
                <FontAwesomeIcon icon={faArrowRight} color="#ffffff" fontSize={20} />
              </Button>
            </ButtonsWrapper>
          )}
        </SectionHeadingWrapper>
        <Swiper
          spaceBetween={15}
          slidesPerView={1}
          modules={[Pagination, Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          loop
          breakpoints={{
            [breakpoints.lg]: {
              slidesPerView: 3,
            },
          }}
          navigation
          className="contentCardsCarousel">
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <CarouselSlideWrapper>
                <ContentCard className="row-gutter" xs={24}>
                  <Rate value={testimonial.rating} disabled />
                  <p style={{ marginTop: '1.5rem' }}>{testimonial.text}</p>
                  <UserInfoWrapper>
                    <div>
                      <p className="name">{testimonial.user}</p>
                      <p className="city">{testimonial.city}</p>
                    </div>
                  </UserInfoWrapper>
                </ContentCard>
                {(size === 'xs' || size === 'sm' || size === 'md') && mobileNavButtons}
              </CarouselSlideWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </ContentContainer>
    </Background>
  );
};

export default Testimonials;
