import React from 'react';
import { Outlet } from 'react-router-dom';

import { ScrollProvider } from 'utils/ScrollContext';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

const MainLayout = ({ children }) => (
  <>
    <ScrollProvider>
      <Navbar />
      {children || <Outlet />}
    </ScrollProvider>
    <Footer />
  </>
);

export default MainLayout;
