import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { GiPositionMarker } from 'react-icons/gi';

import LandingMainImg from 'assets/images/landing_main_img.png';
import { Button } from 'components/Button/Button';
import { ContentContainer } from 'components/Common/Common';
import { useScrollRef } from 'utils/ScrollContext';
import Testimonials from './components/Testimonials';
import InfoCards from './components/InfoCards';
import { LandingTitlesWrapper, FirstSectionWrapper, LandingMainImage, FlexCol, PositionLabel } from './styled';
import EstimationFormSection from './components/EstimationFormSection';
import SavingBanner from './components/SavingBanner';
import ChooseSolarBear from './components/ChooseSolarBear';

const LandingPage = () => {
  const { t } = useTranslation('translation');
  const scrollRef = useScrollRef();

  return (
    <>
      <FirstSectionWrapper>
        <ContentContainer additionalStyles={{ width: '100%' }}>
          <Row>
            <FlexCol xs={{ span: 24 }} lg={{ span: 12 }}>
              <LandingTitlesWrapper>
                <PositionLabel>
                  <GiPositionMarker />
                  {t('landing.landing_position')}
                </PositionLabel>
                <h1>{t('landing.landing_title')}</h1>
                <h3>{t('landing.landing_subtitle')}</h3>
                <Button buttonStyle="dark" onClick={() => scrollRef.current.scrollIntoView({ behavior: 'smooth' })}>
                  <p>{t('landing.landing_main_button')}</p>
                </Button>
              </LandingTitlesWrapper>
            </FlexCol>
            <FlexCol xs={{ span: 24 }} lg={{ span: 12 }}>
              <LandingMainImage src={LandingMainImg} alt="Grid img" />
            </FlexCol>
          </Row>
        </ContentContainer>
      </FirstSectionWrapper>
      <InfoCards />
      <EstimationFormSection ref={scrollRef} />
      <SavingBanner />
      <ChooseSolarBear />
      <Testimonials />
    </>
  );
};

export default LandingPage;
